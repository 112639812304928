import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const changeApprovedEvents = async (id: number) => {
  const endponit = `/events/admin/${id}/approve`
  const response = axios.get(API_URL + endponit)

  return response
}

export const changeFeaturedEvents = async (id: number) => {
  const endponit = `/events/admin/${id}/featured`
  const response = axios.get(API_URL + endponit)

  return response
}

export const changeArchiveEvents = async (id: number) => {
  const endponit = `/events/admin/${id}/archive`
  const response = axios.get(API_URL + endponit)

  return response
}

export const getAdminEvents = async () => {
  const endponit = `/events/admin/`
  const response = axios.get(API_URL + endponit)

  return response
}

export const postRefreshAmount = async (payload: {refreshAmount: number}) => {
  const endponit = `/events/refresh-amount/`
  const response = axios.post(API_URL + endponit, payload)
  return response
}
