import {Column} from 'react-table'
import {Table} from '../../components'
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {changeApprovedEvents, changeArchiveEvents, changeFeaturedEvents, getAdminEvents} from '../../api/be'
import {KTSVG} from '../../../_metronic/helpers'
import moment from 'moment'

type CustomColumn = {
  className?: string
} & Column

export const MainTable = () => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  const getEvents = async () => {
    const res = await getAdminEvents()
    res.data.sort((a: any, b: any) => b.id - a.id)
    setData(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getEvents().catch((err: any) => console.log(err))
  }, [])

  const handleApprove = async (id: number) => {
    setLoading(true)
    await changeApprovedEvents(id)
    getEvents()
  }

  const handleFeatured = async (id: number) => {
    setLoading(true)
    await changeFeaturedEvents(id)
    getEvents()
  }

  const handleArchive = async (id: number) => {
    setLoading(true)
    await changeArchiveEvents(id)
    getEvents()
  }

  const columns: CustomColumn[] = [
    {
      className: 'min-w-100px',
      Header: 'Image',
      Cell: (cell: any) => {
        return (
          <>
            <img src={cell.row.original?.imageUrl} width={100} height={100} alt='' />{' '}
          </>
        )
      },
    },
    {
      className: 'min-w-100px',
      Header: 'Title',
      accessor: 'title',
    },
    {
      className: 'min-w-100px',
      Header: 'Description',
      accessor: 'description',
    },
    {
      className: 'min-w-100px',
      Header: 'From Date',
      accessor: 'fromDate',
      Cell: (cell: any) => {
        return <>{moment(cell.row.original.fromDate).format('YYYY/MM/DD LT')}</>
      },
    },
    {
      className: 'min-w-100px',
      Header: 'End Date',
      accessor: 'endDate',
      Cell: (cell: any) => {
        return <>{moment(cell.row.original.endDate).format('YYYY/MM/DD LT')}</>
      },
    },
    {
      className: 'min-w-100px',
      Header: 'Format',
      accessor: 'format',
    },
    {
      className: 'min-w-100px',
      Header: 'City',
      accessor: 'city',
    },
    {
      className: 'min-w-100px',
      Header: 'Region',
      accessor: 'region',
    },
    {
      className: 'min-w-100px',
      Header: 'Main',
      accessor: 'mainSide',
      Cell: (cell: any) => {
        return (
          <>
            {cell.row.original.mainSide && (
              <>
                <KTSVG
                  path='/media/icons/duotune/general/gen037.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              </>
            )}
          </>
        )
      },
    },
    {
      className: 'min-w-100px',
      Header: 'Record',
      accessor: 'record',
      Cell: (cell: any) => {
        return (
          <>
            {cell.row.original.record && (
              <>
                <KTSVG
                  path='/media/icons/duotune/general/gen037.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              </>
            )}
          </>
        )
      },
    },
    {
      className: 'min-w-100px',
      Header: 'Page',
      accessor: 'pageUrl',
      Cell: (cell: any) => {
        return (
          <>
            <Link to={cell.row.original?.pageUrl} target='_blank' rel='noreferrer'>
              URL
            </Link>
          </>
        )
      },
    },
    {
      className: 'min-w-100px',
      Header: 'Action',
      Cell: (cell: any) => {
        console.log(cell.row.original)
        return (
          <div className='d-flex'>
            <button
              className='btn btn-primary btn-active-primary me-2'
              onClick={() => handleApprove(cell.row.original.id)}
            >
              {cell.row.original.approved ? 'Disapprove' : 'Approve'}
            </button>

            <button
              className='btn btn-primary btn-active-primary me-2'
              onClick={() => handleFeatured(cell.row.original.id)}
            >
              {cell.row.original.featured ? 'Feature' : 'Unfeature'}
            </button>

            <button
              className='btn btn-primary btn-active-primary'
              onClick={() => handleArchive(cell.row.original.id)}
            >
              {cell.row.original.archive ? 'Unarchive' : 'Archive'}
            </button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Table
        className='mb-5 mb-xl-10'
        title='Events'
        columns={columns}
        data={data}
        isLoading={loading}
        pageSize={100}
      />
    </>
  )
}
